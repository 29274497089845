import { Card } from 'react-bootstrap';

import { IProduct } from '../../../../store/ducks/products/types';
import Description from '../description/Description';

interface Props {
  product: IProduct | undefined;
}

function ProductInfo({ product }: Props) {
  return (
    <>
      {product && (
        <section className="my-3">
          <Card className="shadow" style={{ borderRadius: '20px' }}>
            <Card.Header style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
              <h4 className="mb-0">Informações do produto</h4>
            </Card.Header>
            <Card.Body>
              <Description description={product.description} />
            </Card.Body>
          </Card>
        </section>
      )}
    </>
  );
}

export default ProductInfo;
