import Echo from 'laravel-echo';

import { store } from '../store';
import * as OrderActions from '../store/ducks/order/actions';
import { IOrder, IResumedOrder } from '../store/ducks/order/types';
import * as PaymentActions from '../store/ducks/payment/actions';
import { getToken } from './auth';

window.io = require('socket.io-client');

declare global {
  interface Window {
    io: any;
  }
}

interface IOrderVersion {
  socket: null;
  time_id: number;
  v1: IOrder;
  v2: IResumedOrder;
}

const { dispatch } = store;
const state: { echo: Echo | null } = { echo: null };

export const initiateSocket = () => {
  const { NODE_ENV, REACT_APP_BV_URL } = process.env;
  const bvUrl = NODE_ENV === 'production' ? REACT_APP_BV_URL : REACT_APP_BV_URL?.slice(0, -5);

  state.echo = new Echo({
    host: `${bvUrl}`,
    broadcaster: 'socket.io',
    reconnectionAttempts: 3,
    auth: {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  });

  state.echo.connector.socket.on('reconnect_failed', () =>
    console.error('Falha na conexão do socket...'),
  );
};

export const disconnectSocket = () => {
  if (state.echo) state.echo.connector.socket.disconnect();
};

const getDefaultChannel = (orderId: number) => `private-webapp.order.${orderId}`;

export const subscribeToPayment = ({
  orderId,
  callback = () => {},
}: {
  orderId: number;
  callback?: (order: IOrder) => void;
}) => {
  if (!state.echo) return true;

  return state.echo
    .channel(getDefaultChannel(orderId))
    .listen('PaymentStatusChange', ({ v1 }: IOrderVersion) => {
      const validPayments = v1.payments?.filter((payment) => !payment.deleted_at);
      const isPaid = validPayments?.length
        ? validPayments?.every((p) => p.status === 'paid')
        : false;

      const hasToShowModal = validPayments && validPayments.length > 0 && isPaid;

      if (hasToShowModal) dispatch(PaymentActions.setShowPaidAlert(true));

      dispatch(OrderActions.loadSuccess(v1));
      callback(v1);
    });
};

export const subscribeToOrder = (orderId: number) => {
  if (!state.echo) return true;

  return state.echo
    .channel(getDefaultChannel(orderId))
    .listen('UpdatedOrder', ({ v1 }: IOrderVersion) => {
      dispatch(OrderActions.loadSuccess(v1));
    });
};
