import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useWebContext } from '@/resources/hooks/useWebContext';
import { maskMoney } from '@/services/global';
import { ApplicationState } from '@/store';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

import ShippingDiscountModal from './shippingDiscountModal/ShippingDiscountModal';

function DeliveryDiscountAlert() {
  const { order } = useWebContext();
  const [show, setShow] = useState<boolean>(false);

  const deliveryDiscounts = useSelector<ApplicationState, IDeliveryDiscountData[]>(
    (state) => state.deliveryDiscount.data,
  );

  const isFreeDelivery = order?.cart.delivery_tax === 0;
  const deliveryDiscount = deliveryDiscounts?.find(
    (discount) => discount.type_discount === 'free' || discount.type_discount === 'price',
  );

  return (
    <>
      {!isFreeDelivery && deliveryDiscounts.length > 0 && deliveryDiscount && (
        <button
          type="button"
          className="btn unavailable-free-shipping-btn"
          onClick={() => setShow(true)}
        >
          <b>
            {deliveryDiscount.type_discount === 'free' ? 'FRETE GRÁTIS' : 'DESCONTO'}
            {deliveryDiscount.type_discount === 'price' && ` de ${deliveryDiscount.price_discount}`}
          </b>{' '}
          em compras a partir de{' '}
          <b>
            {maskMoney(deliveryDiscount.min_price_order, true)} ({deliveryDiscount.max_distance}
            km)
          </b>
        </button>
      )}

      <ShippingDiscountModal show={show} setShow={setShow} />
    </>
  );
}

export default DeliveryDiscountAlert;
